import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import configuration from './config.json'

//const admin_endopint = 'http://localhost:8090/private/admin/'
//const admin_endopint = 'https://gmg8gpeq5a.execute-api.eu-west-1.amazonaws.com/api/private/admin/'
Amplify.configure({

    API: {
        endpoints: [
            {
                name: "admin",
                endpoint: configuration.admin_endopint
            }   
        ]
    },
    Auth: {
        region: 'eu-west-1',
        userPoolId: configuration.userPoolId,//'eu-west-1_7nR2kTTtx',
        userPoolWebClientId: configuration.userPoolWebClientId //'441mrabn388ed5oeun01lq3bc'
    }
  });



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
