import React, { Component } from 'react';
import { Table } from 'reactstrap';
import {getData} from './../Utils'

class TrailerSession extends Component {
    constructor(props) {
        super(props);
        this.state = { sessions:[] }
        const { match: { params } } = this.props;
        if (params.id) {this.id = params.id}
    }
    componentDidMount(){
        getData('trailer/'+this.id+'/sessions').then( sessions =>{
            console.log(sessions)
            this.setState({sessions:sessions.sort((a,b) => b.id - a.id)})
            }
        )
    }

    render() {
        const sessions = this.state.sessions.map(s => <tr key={s.id}> 
            <td>{s.started_at}</td><td>{s.ended_at}</td><td>{s.duration_seconds}</td><td>{s.price_total}</td>
            <td>{s.user.name}</td><td>{s.user.email}</td><td>{s.user.phone}</td>
        </tr>)
        return (
            <Table striped hover>
                <thead>
                    <tr>
                        <th>Start</th><th>End</th><th>Duration</th><th>Price</th><th>User</th><th>Email</th><th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {sessions}
                </tbody>
            </Table>
          );
    }
}
 
export default TrailerSession;