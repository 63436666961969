import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CustomNavbar.css";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from "reactstrap";
import { Auth } from "aws-amplify";
import { getData } from "../Utils";
import { useGlobalState } from "../global";

function CustomNavbar() {
  const [state, dispatch] = useGlobalState();
  const [superadmin, setSuperadmin] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [expand, handleExpand] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (typeof groups == "object" && groups.includes("superadmin")) {
        setSuperadmin(true);
        setAdmin(true);
        dispatch({ user_type: "admin" });
      }
    });
  }, []);

  useEffect(() => {
    getData("self").then((me) => {
      if (me.type === "administrator") {
        setAdmin(true);
        dispatch({ user_type: "admin" });
      }
    });
  }, []);
  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/" className="pl-3">
        {/* <img alt="logo" src="moduulo_transparent_600x84.png" className="logo"/> */}
      </NavbarBrand>
      <NavbarToggler onClick={handleExpand} />
      <Collapse navbar>
        <Nav className="ml-auto">
          {superadmin && (
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/operators">
                Operators
              </Link>{" "}
            </NavItem>
          )}
          {superadmin && (
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/devices">
                Devices
              </Link>{" "}
            </NavItem>
          )}
          {admin && (
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/types">
                Types
              </Link>{" "}
            </NavItem>
          )}
          {admin && (
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/points">
                Rental Points
              </Link>{" "}
            </NavItem>
          )}
          {admin && (
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/docks">
                Docks
              </Link>{" "}
            </NavItem>
          )}
          <NavItem>
            {" "}
            <Link className="navbarLink" to="/treilers">
              Trailers
            </Link>{" "}
          </NavItem>
          {admin && (
            <NavItem>
              <Link className="navbarLink" to="/statistics">
                Statistics
              </Link>{" "}
            </NavItem>
          )}
          {admin && (
            <NavItem>
              <Link className="navbarLink" to="/statisticsv2">
                Statistics(v2)
              </Link>{" "}
            </NavItem>
          )}
          {admin && (
            <NavItem>
              <Link className="navbarLink" to="/users">
                Users
              </Link>{" "}
            </NavItem>
          )}
          {admin && (
            <NavItem>
              <Link className="navbarLink" to="/settings">
                Settings
              </Link>{" "}
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
}

class CustomNavbar_c extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      superadmin: false,
    };
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    this.setState({ expand: !this.state.expand });
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      console.log(groups);
      if (typeof groups == "object" && groups.includes("superadmin")) {
        this.setState({ superadmin: true });
      }
    });
  }
  render() {
    const sa = this.state.superadmin;
    return (
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/" className="pl-3">
          {/* <img alt="logo" src="moduulo_transparent_600x84.png" className="logo"/> */}
        </NavbarBrand>

        <NavbarToggler onClick={this.handleExpand} />
        <Collapse navbar>
          <Nav className="ml-auto">
            {sa && (
              <NavItem>
                {" "}
                <Link className="navbarLink" to="/operators">
                  Operators
                </Link>{" "}
              </NavItem>
            )}
            {sa && (
              <NavItem>
                {" "}
                <Link className="navbarLink" to="/devices">
                  Devices
                </Link>{" "}
              </NavItem>
            )}
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/types">
                Types
              </Link>{" "}
            </NavItem>
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/points">
                Rental Points
              </Link>{" "}
            </NavItem>
            <NavItem>
              {" "}
              <Link className="navbarLink" to="/treilers">
                Trailers
              </Link>{" "}
            </NavItem>
            <NavItem>
              <Link className="navbarLink" to="/statistics">
                Statistics
              </Link>{" "}
            </NavItem>
            <NavItem>
              <Link className="navbarLink" to="/users">
                Users
              </Link>{" "}
            </NavItem>
            <NavItem>
              <Link className="navbarLink" to="/settings">
                Settings
              </Link>{" "}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default CustomNavbar;
