import React, {Component} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withAuthenticator, Greetings,SignIn,ConfirmSignIn,VerifyContact,ForgotPassword,RequireNewPassword,SignUp, ConfirmSignUp } from 'aws-amplify-react';
import {GlobalStateProvider, Errors, Alerts} from './global'
import CustomNavbar from './component/CustomNavbar'
import RentalPoints from './component/RentalPoints'
import Treilers from './component/Treilers';
import RentalPoint from './component/RentalPoint';
import Treiler from './component/Treiler'
import Statistics from './component/Statistics';
import Users from './component/Users';
import User from './component/User';
import Settings from './component/Settings';
import Types from './component/Types';
import Type from './component/Type';
import Operators from './component/Operators';
import Operator from './component/Operator';
import Devices from './component/Devices';
import TrailerSession from './component/TrailerSessions';
import Session from './component/Session';
import Invoice from './component/Invoice';
import Docks from './component/Docks'
import Dock from './component/Dock';
import StatisticsV2 from './component/StatisticsV2';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { }

  }
  render() { 
    return (  
      <GlobalStateProvider>
      <Router>
        <CustomNavbar />
        <Switch>
          <div className="container">
            <div className="mainBody">
            <Route path="/points" component = {RentalPoints} />
            <Route path="/point/:id" component = {RentalPoint} />
            <Route exact path="/point" component = {RentalPoint} />
            
            <Route path="/treilers" component = {Treilers} />
            <Route path="/treiler/:id" component = {Treiler} />
            <Route exact path="/treiler" component = {Treiler} />
            
            <Route path="/docks" component = {Docks} />
            <Route path="/dock/:id" component = {Dock} />
            <Route exact path="/dock" component = {Dock} />

            <Route path="/operators" component = {Operators} />
            <Route path="/operator/:id" component = {Operator} />
            <Route exact path="/operator" component = {Operator} />

            <Route path="/types" component = {Types} />
            <Route path="/type/:id" component = {Type} />
            <Route exact path="/type" component = {Type} />

            <Route path="/statistics" component = {Statistics}/>
            <Route path="/statisticsv2" component = {StatisticsV2}/>
            <Route path="/users" component = {Users} />
            <Route path="/user/:id" component = {User} />
            <Route exact path="/user" component = {User} />
            <Route path="/settings" component = {Settings} />
            <Route path="/devices" component = {Devices} />

            <Route path="/trailerSessions/:id" component ={TrailerSession} />
            <Route path="/session/:id" component ={Session} />
            <Route path="/invoice/:id" component ={Invoice} />
            </div>
          </div>
        </Switch>
      <Errors/>
      <Alerts/>
      </Router>
      </GlobalStateProvider>
    );
  }
}
 

export default withAuthenticator(App, true, [  <Greetings />,
  <SignIn />,
  <SignUp />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ConfirmSignUp/>,
  <RequireNewPassword/>,
  <ForgotPassword />]);
