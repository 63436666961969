import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {getData} from './../Utils'

export default function Invoice() {
    const params = useParams()
    const id = params.id
    const [file, updateFile] = useState(null)
    useEffect(() => {
        getData(`sessions/${id}/invoice`).then( r =>{
            updateFile(r.invoice.replace('\n',''))
        })
    },[])
    if (file){
        return (
            <iframe 
                src = {file}  width="100%" height="800"
            />
        )
    }
    return (<></>)
}