import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, ButtonGroup } from "reactstrap";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Card, CardTitle, CardText } from "reactstrap";

import { getData, postData } from "./../Utils";
import DeleteModal from "../Utils";

import "./Treiler.css";
import Comments from "./Comments";
import CommentsTable from "./CommentsTable";

class Treiler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      points: [],
      devices: [],
      docks: [],
      modal: false,
      end_session_modal: false,
      reg_number: "",
      device_serial: "None",
      trailer_type: 0,
      questions: [],
      is_admin: false,
    };
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.id = params.id;
    }
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeValueBoolean = this.onChangeValueBoolean.bind(this);
    this.onChangeRentalPoint = this.onChangeRentalPoint.bind(this);
    this.setRSelected = this.setRSelected.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.endSessionPressed = this.endSessionPressed.bind(this);
    this.confirm = this.confirm.bind(this);
    this.viewSessions = this.viewSessions.bind(this);
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);
  }

  componentDidMount() {
    getData("rentalpoints").then((points) => {
      points.unshift({ id: 0, name: "None" });
      console.log(points);
      this.setState({ points });
    });
    let operator_devices_url = "operator_devices";
    let operator_docks_url = "docks/unassigned";
    if (this.id) {
      operator_devices_url = operator_devices_url + "/?trailer_id=" + this.id;
      operator_docks_url = operator_docks_url + "/?trailer_id=" + this.id;
    }
    getData(operator_devices_url).then((devices) => {
      let mydevices = devices.map((t) => {
        return { id: t.serial, name: t.serial };
      });
      mydevices.unshift({ serial: "None", name: "None" });
      this.setState({ devices: mydevices });
    });
    getData(operator_docks_url).then((docks) => {
      docks.unshift({ id: 0, rental_point_id: -1, dock_id: "None" });
      this.setState({ docks });
    });
    getData("types").then((types) => {
      types.unshift({ id: 0, name: "None" });
      let mytypes = types.map((t) => {
        return { id: t.id, name: t.name };
      });
      this.setState({ types: mytypes });
    });
    getData("self").then((me) => {
      const is_admin = me.type === "administrator" ? true : false;
      this.setState({ is_admin });
    });
    if (this.id) {
      getData("trailer/" + this.id).then((treiler) => {
        console.log(treiler);
        this.setState(treiler);
      });
      getData("trailer/" + this.id + "/questions").then((questions) => {
        this.setState({ questions });
      });
    } else {
      this.setState({ reg_number: "", trailer_type: 0, device_serial: "None", rental_point_id: 0, comment: "", active: true, status: "ready", allow_relocate: false });
    }
  }
  onChangeValue(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  onChangeValueBoolean(event) {
    this.setState({ [event.target.name]: event.target.value === "true" });
  }
  onChangeRentalPoint(event) {
    console.log(`onChangeRentalPoint run ${event}`);
    this.setState({ dock_id: 0 });
    this.onChangeValue(event);
  }
  setRSelected(state) {
    this.setState({ active: state });
  }
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }

  viewSessions() {
    this.props.history.push("/trailerSessions/" + this.id);
  }
  endSessionPressed(end_session_id) {
    const { end_session_modal } = this.state;
    this.setState({ end_session_id });
    this.setState({ end_session_modal: !end_session_modal });
  }
  confirm() {
    console.log(this.state.end_session_id);
    this.setState({ end_session_modal: false });
    getData("sessions/" + this.state.end_session_id + "/end").then((r) => {
      this.setState({ status: "ready" });
    });
  }
  toggle() {
    const { modal } = this.state;
    console.log(modal);
    this.setState({ modal: !modal });
  }
  save() {
    console.log(this.state);
    postData("trailers", this.state).then((r) => {
      console.log(r);
      window.location.href = "/treilers";
    });
  }
  activate() {
    postData("trailer/" + this.id + "/active", { state: true }).then((r) => {
      window.location.reload();
    });
  }
  deactivate() {
    postData("trailer/" + this.id + "/active", { state: false }).then((r) => {
      window.location.reload();
    });
  }
  render() {
    const types = this.state.types.map((type) => (
      <option key={type.id} value={type.id}>
        {type.name}
      </option>
    ));
    const devices = this.state.devices.map((device) => (
      <option key={device.serial} value={device.serial}>
        {device.name}
      </option>
    ));
    const points = this.state.points.map((point) => (
      <option key={point.id} value={point.id}>
        {point.name}
      </option>
    ));
    const docks = this.state.docks
      .filter((d) => d.rental_point_id == this.state.rental_point_id || d.rental_point_id === -1)
      .map((dock) => (
        <option key={dock.id} value={dock.id}>
          {dock.dock_id}
        </option>
      ));
    const setRSelected = this.setRSelected;
    const active = this.state.active;
    const { imagePreviewUrl, modal } = this.state;
    const toggle = this.toggle;
    const endSessionPressed = this.endSessionPressed;
    const end_session_modal = this.state.end_session_modal;
    const inrent = this.state.status === "inrent";
    const disabled = !this.state.is_admin;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img alt="preview" className="treiler-image-preview" src={imagePreviewUrl} />;
    }
    return (
      <React.Fragment>
        <Button color="primary" onClick={toggle}>
          Start new Rent session
        </Button>
        {this.id && (
          <Button color="primary" onClick={this.viewSessions}>
            View Sessions
          </Button>
        )}
        {this.id && active && (
          <Button color="secondary" onClick={this.deactivate}>
            Deactivate
          </Button>
        )}
        {this.id && !active && (
          <Button color="primary" onClick={this.activate}>
            Activate
          </Button>
        )}
        <Form>
          <FormGroup>
            <Label>Reg Number</Label>
            <Input type="text" name="reg_number" value={this.state.reg_number} onChange={this.onChangeValue} disabled={disabled} />
          </FormGroup>
          <FormGroup>
            <Label>Treiler type</Label>
            <Input type="select" name="trailer_type" value={this.state.trailer_type} onChange={this.onChangeValue} disabled={disabled}>
              {types}
            </Input>
          </FormGroup>
          {/* <FormGroup>
            <Label>Attached Device</Label>
            <Input type="select" name="device_serial" value={this.state.device_serial} onChange={this.onChangeValue} disabled={disabled}>
              {devices}
            </Input>
          </FormGroup> */}
          <FormGroup>
            <Label>Select Rental Point</Label>
            <Input type="select" name="rental_point_id" value={this.state.rental_point_id} onChange={(e) => this.onChangeRentalPoint(e)} disabled={disabled}>
              {points}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Select Dock</Label>
            <Input type="select" name="dock_id" value={this.state.dock_id} onChange={this.onChangeValue} disabled={disabled}>
              {docks}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Allow Relocation</Label>
            <Input type="select" name="allow_relocate" value={this.state.allow_relocate} onChange={this.onChangeValueBoolean}>
              <option key={"false"} value={false}>
                False
              </option>
              <option key={"true"} value={true}>
                True
              </option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Comment</Label>
            <Input type="textarea" name="comment" value={this.state.comment || ""} onChange={this.onChangeValue} />
          </FormGroup>
          <FormGroup tag="fieldset">
            <Label>Status: {this.state.status || ""}</Label>
          </FormGroup>
          <FormGroup tag="fieldset">
            <Label>Active: {active ? "true" : "false"}</Label>
          </FormGroup>
          {inrent === true && (
            <FormGroup tag="fieldset">
              <Label>
                {this.state.renter_info.name || ""}, {this.state.renter_info.phone || ""}, {this.state.renter_info.email || ""}, Started at: {this.state.session_info.started_at || ""}{" "}
              </Label>
              <Button color="danger" onClick={() => endSessionPressed(this.state.session_info.id)}>
                End Rent session
              </Button>
            </FormGroup>
          )}
          <div className="treiler-image-preview"> {$imagePreview}</div>
          {!disabled && <input type="file" onChange={(e) => this._handleImageChange(e)} />}
          <Button color="primary" onClick={() => this.save()} disabled={disabled}>
            Save
          </Button>
        </Form>
        <h3>Trailer Comments</h3>
        <CommentsTable comments={this.state.questions} />
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Start new session</ModalHeader>
          <ModalBody>
            <div>Enter Renter information</div>
            <Form>
              <FormGroup>
                <Label>Name</Label>
                <Input type="text" name="name" value={this.state.name || ""} onChange={this.onChangeValue} />
              </FormGroup>
              <FormGroup>
                <Label>Phone numner</Label>
                <Input type="text" name="phone_number" value={this.state.phone_number || ""} onChange={this.onChangeValue} />
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input type="text" name="email" value={this.state.email || ""} onChange={this.onChangeValue} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Start
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <DeleteModal modal={end_session_modal} toggle={endSessionPressed} confirm={this.confirm} />
      </React.Fragment>
    );
  }
}

export default Treiler;
