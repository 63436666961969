import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import DeleteModal from "../Utils";
import { getData, delData, DynTable } from "../Utils";
import { useHistory } from "react-router-dom";

export default function Docks() {
  const history = useHistory();
  const [docks, setDocks] = useState([]);
  const [modal, setModal] = useState(false);
  const [toDelete, setToDelete] = useState(0);

  useEffect(() => {
    getData("docks").then((docks) => setDocks(docks));
  }, []);

  const toggle = (id) => {
    setToDelete(id);
    setModal(!modal);
  };

  const confirm = () => {
    setModal(false);
  };
  return (
    <>
      <Button color="primary" onClick={() => history.push("/dock")}>
        Add New
      </Button>
      <DrawDynamicDocksTable data={docks} toggle={toggle} />
      <DeleteModal modal={modal} toggle={toggle} confirm={confirm} />
    </>
  );
}

function DrawDynamicDocksTable(props) {
  const columns = [
    {
      Header: "Docks",
      columns: [
        {
          Header: "Rental Point",
          accessor: "point_name",
          sortType: "basic",
        },
        {
          Header: "Dock id",
          accessor: "dock_id",
          sortType: "basic",
        },
        {
          Header: "Device",
          accessor: "device_serial",
          sortType: "basic",
        },
        {
          Header: "Int bat",
          accessor: "internal_battery",
          sortType: "basic",
        },
        {
          Header: "Ext bat",
          accessor: "external_battery",
          sortType: "basic",
        },
        {
          Header: "Trailer",
          accessor: "reg_number",
        },
        {
          Header: "Actions",
          accessor: "actions",
        },
      ],
    },
  ];
  const { toggle, data } = props;
  data.map((d) => {
    d.actions = (
      <>
        <a href={`/dock/${d.id}`}>
          <Button color="primary" style={{ margin: "2px" }}>
            Details
          </Button>
        </a>{" "}
        <Button color="danger" style={{ margin: "2px" }} onClick={() => toggle(d.id)}>
          Delete
        </Button>
      </>
    );
    return d;
  });
  return <DynTable columns={columns} data={data} />;
}
