import React, { Component,useState,useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import {useGlobalState} from './../global'
import DeleteModal, { getData, delData } from '../Utils';
import { useHistory } from 'react-router-dom';


function Users(){
    const [state, dispatch] = useGlobalState(); 
    const [users, setUsers] = useState([])
    const [rows, setRows] = useState("")
    const [modal, setModal] = useState(false)
    const [toDelte, settoDelte] = useState(null)
    const history = useHistory();

    useEffect( () => {
        // const users = [
        //     {id:'11', email:'user1@domain.com', type:'Admin', created:'2019-12-01'},
        //     {id:'12', email:'user2@domain.com', type:'Operator', created:'2019-12-01'},
        //     {id:'13', email:'user3@domain.com', type:'Operator', created:'2019-12-01'}
        // ]
        getData('users').then(users =>{
            setUsers(users) 
        })
      }, []);

    function addNewPressed(){
        history.push('/user')
    }
    function editPressed(id){
        history.push(`/user/${id}`)
    }
    function toggle(id){
        settoDelte(id)
        setModal(!modal)
    }
    function confirm(){
        console.log(toDelte)
        delData(`users/${toDelte}`).then(o => {
            const users_left = users.filter(o => o.id != toDelte)
            setUsers(users_left)
        }).catch(error => {
            dispatch({error:`Error. Could not delete ${error}`})
            })
        setModal(false)
    }
    useEffect( () => {
        const rows = users.map(user =>
            <tr key={user.id}>
                <th>{user.email}</th><th>{user.type}</th><th>{user.created}</th>
                <th>
                    <Button color="primary" style={{margin:"2px"}} onClick={() => editPressed(user.id)}>Edit</Button>
                    <Button color="danger" style={{margin:"2px"}} onClick={() => toggle(user.id)}>Delete</Button>
                </th>
            </tr>)
        setRows(rows)
      }, [users]);

      return ( 
        <React.Fragment>
            <Button color="primary" onClick={addNewPressed}>Add new</Button>
            <Table striped hover>
                <thead>
                <tr>
                    <th>Email</th><th>Type</th><th>Created</th><th>Actions</th>
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
            <DeleteModal modal={modal} toggle={toggle} confirm={confirm} />
        </React.Fragment> );
      
}



class Users_c extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            modal:false
          }
    this.addNewPressed = this.addNewPressed.bind(this)
    this.toggle = this.toggle.bind(this)
    this.confirm = this.confirm.bind(this)
    }
    componentDidMount(){
        const users = [
            {id:'11', email:'user1@domain.com', type:'Admin', created:'2019-12-01'},
            {id:'12', email:'user2@domain.com', type:'Operator', created:'2019-12-01'},
            {id:'13', email:'user3@domain.com', type:'Operator', created:'2019-12-01'}
        ]
        this.setState({users})
    }
    addNewPressed(){
        this.props.history.push('/user')
    }
    toggle(){
        const {modal} = this.state
        console.log(modal)
        this.setState({modal:!modal})
    }
    confirm(){
        console.log("yes")
        this.setState({modal:false})
    }
    render() {
        const users =  this.state.users.map(user =>
        <tr key={user.id}>
            <th>{user.email}</th><th>{user.type}</th><th>{user.created}</th>
            <th><Button color="primary" onClick={this.addNewPressed}>Edit</Button> <Button color="danger" onClick={this.toggle}>Delete</Button></th>
        </tr>)
        return ( 
        <React.Fragment>
            <Button color="primary" onClick={this.addNewPressed}>Add new</Button>
            <Table striped hover>
                <thead>
                <tr>
                    <th>Email</th><th>Type</th><th>Created</th><th>Actions</th>
                </tr>
                </thead>
                <tbody>{users}</tbody>
            </Table>
            <DeleteModal modal={this.state.modal} toggle={this.toggle} confirm={this.confirm} />
        </React.Fragment> );
    }
}
 
export default Users;