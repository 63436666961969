import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import { getData, DynTable, postData, localizeTZ } from "./../Utils";

import { CSVLink } from "react-csv";

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      rental_points: [],
      treilers: [],
      rentailpoint: "all",
      treiler: "all",
    };
    this.startDate = this.setStartDate.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.recharge = this.recharge.bind(this);
  }

  decompSeconds(sec) {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = sec - hours * 3600 - minutes * 60;
    return `${hours}:${minutes}:${seconds}`;
  }

  componentDidMount() {
    getData("sessions").then((sessions) => {
      console.log(sessions);
      getData("rentalpoints").then((points) => {
        console.log(points);
        sessions.map((s) => {
          const point_name = points.filter((p) => p.id == s.rental_point_id)[0].name;
          const point_code = points.filter((p) => p.id == s.rental_point_id)[0].code;
          s.rental_point_id = point_name ? point_name : s.rental_point_id;
          s.rental_point_code = point_code;
          s.price_total = s.price_total / 100;
          s.duration_hour = this.decompSeconds(s.duration_seconds);
          return s;
        });
        const localeSessions = sessions.map((s) => {
          s.started_at = localizeTZ(s.started_at);
          s.ended_at = localizeTZ(s.ended_at);
          s.first_unlock_time = localizeTZ(s.first_unlock_time);
          s.payment_comment = s.refund_comment ? s.refund_comment : s.payment_solved_comment ? s.payment_solved_comment : "";
          return s;
        });
        this.setState({ sessions: localeSessions });
        let rental_points = [...new Set(sessions.map((item) => item.rental_point_id))];
        rental_points.unshift("all");
        let treilers = [...new Set(sessions.map((item) => item.trailer_reg_number))];
        treilers.unshift("all");
        this.setState({ rental_points, treilers });
      });
    });
  }
  setStartDate(date) {
    this.setState({ startDate: date });
  }
  onChangeValue(event) {
    console.log(event.target.name);
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }
  details(id) {
    this.props.history.push(`/session/${id}`);
  }
  filterSessions(sessions, startDate, endDate) {
    const { rentailpoint, treiler } = this.state;
    if (rentailpoint !== "all") {
      sessions = sessions.filter((i) => i.rental_point_id == rentailpoint);
    }
    if (treiler !== "all") {
      sessions = sessions.filter((i) => i.trailer_reg_number === treiler);
    }
    sessions = sessions.filter((i) => new Date(i.started_at) <= new Date(endDate));
    sessions = sessions.filter((i) => new Date(i.started_at) >= new Date(startDate));
    return sessions;
  }
  recharge(id) {
    postData(`sessions/${id}/recharge`, {})
      .then((r) => {
        console.log(r);
        let sessions = this.state.sessions;
        sessions = sessions.map((s) => {
          if (s.id == id) {
            s.payement_status = "Pending";
          }
          return s;
        });
        this.setState({ sessions });
      })
      .catch(console.log("error"));
  }
  render() {
    const defaultStart = new Date(new Date().getTime() - 24 * 3600 * 1000 * 7);
    const rental_points = this.state.rental_points.map((point) => (
      <option key={point} value={point}>
        {point}
      </option>
    ));
    const treilers = this.state.treilers.map((treiler) => (
      <option value={treiler} key={treiler}>
        {treiler}
      </option>
    ));
    const startDate = this.state.startDate || defaultStart;
    const endDate = this.state.endDate || new Date();
    const { rentailpoint, treiler } = this.state;
    let { sessions } = this.state;

    sessions = this.filterSessions(sessions, startDate, endDate);
    return (
      <React.Fragment>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">Rental Point</Label>
            <Input type="select" name="rentailpoint" onChange={this.onChangeValue}>
              {rental_points}
            </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">Treiler</Label>
            <Input type="select" name="treiler" onChange={this.onChangeValue}>
              {treilers}
            </Input>
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">Start</Label>
            <DatePicker selected={startDate} onChange={(date) => this.setStartDate(date)} />
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">End</Label>
            <DatePicker selected={endDate} onChange={(date) => this.setState({ endDate: date })} />
          </FormGroup>
        </Form>
        {sessions && <DrawDynamidStatiscsTable sessions={sessions} recharge={this.recharge} />}
        <div className="flex-content">
          {sessions && <Total sessions={sessions} />}
          {sessions && (
            <CSVLink data={sessions} filename={"rental_sessions.csv"}>
              {" "}
              CSV
            </CSVLink>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function Total(props) {
  const total = props.sessions.map((i) => i.price_total).reduce((a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b), 0);
  return <div>Total: {total} €</div>;
}

function DrawDynamidStatiscsTable(props) {
  const columns = [
    {
      Header: "Sessions",
      columns: [
        {
          Header: "session id",
          accessor: "link",
          sortType: "basic",
        },
        {
          Header: "Reg NR",
          accessor: "trailer_reg_number",
          sortType: "basic",
        },
        {
          Header: "Rentail point",
          accessor: "rental_point_id",
          sortType: "basic",
        },
        {
          Header: "Code",
          accessor: "rental_point_code",
          sortType: "basic",
        },
        {
          Header: "email",
          accessor: "email",
          sortType: "basic",
        },
        {
          Header: "Start",
          accessor: "started_at",
          sortType: "basic",
        },
        {
          Header: "End",
          accessor: "ended_at",
          sortType: "basic",
        },
        {
          Header: "Duration",
          accessor: "duration_hour",
          sortType: "basic",
        },
        {
          Header: "Payment status",
          accessor: "payement_status",
          sortType: "basic",
        },
        {
          Header: "Payment comment",
          accessor: "payment_comment",
          sortType: "basic",
        },
        {
          Header: "Price",
          accessor: "price_total",
          sortType: "basic",
        },
        {
          Header: "Recharge",
          accessor: "recharge",
          sortType: "basic",
        },
        {
          Header: "Invoice",
          accessor: "invoice",
          sortType: "basic",
        },
      ],
    },
  ];
  const data = props.sessions;
  const recharge = props.recharge;

  data.map((d) => {
    d.link = <a href={`/session/${d.id}`}>{d.id}</a>;
    d.recharge = !["SUCCEEDED", "REFUNDED", "SOLVED"].includes(d.payement_status) && d.price_total > 0 && !d.end_user_id.startsWith("manual_") ? <Button onClick={() => recharge(d.id)}>Recharge</Button> : "";
    d.invoice = d.end_user_id && !d.end_user_id.startsWith("man") ? <Link to={`/invoice/${d.id}`}>Invoice</Link> : "";
    return d;
  });
  console.log(data);
  return <DynTable columns={columns} data={data} />;
}

export default Statistics;
