import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {postData, getData} from './../Utils'


class Operator extends Component {
    constructor(props) {
        super(props);
        this.state = {name:"", email:""}
        const { match: { params } } = this.props;
        if (params.id && (this.state = {id:params.id}))
        this.save = this.save.bind(this)
        this.onChangeValue = this.onChangeValue.bind(this)
        this._handleImageChange = this._handleImageChange.bind(this)
    }
    onChangeValue(event){
        this.setState({[event.target.name]: event.target.value}) 
    }
    componentDidMount(){
        if (this.state.id){
            getData('operator/'+this.state.id).then( operator =>{
                console.log(operator)
                this.setState(operator)
                }
            )
        }
        else {
            this.setState({name:"", email:""})
        }

    }
    _handleImageChange(e) {
        e.preventDefault();
  
        let reader = new FileReader();
        let file = e.target.files[0];
  
        reader.onloadend = () => {
            this.setState({
                file: file,
                logo: reader.result
            });
        }
        reader.readAsDataURL(file)
      }
    save(){
        console.log(this.state)
        postData('operators',this.state).then(r => {
            console.log(r)
            console.log('sent')
            window.location.href = '/operators'
                }).then(
        )
    }
    render() { 
        const {logo} = this.state
        let imagePreview = null
        if (logo) {
            imagePreview = (<img alt="preview" className="treiler-image-preview" src={logo}/>);
        }

        return ( 
            <Form>
                <FormGroup>
                    <Label>Name</Label>
                    <Input type="text" name="name" value = {this.state.name || ''} onChange={this.onChangeValue}/>
                </FormGroup>
                <FormGroup>
                    <Label>Admin's email address</Label>
                    <Input type="email" name="email" value = {this.state.email || ''} onChange={this.onChangeValue}/>
                </FormGroup>
                <div className="treiler-image-preview"> {imagePreview}</div>
                <input type="file" onChange={(e) => this._handleImageChange(e)}/>
              <Button color="primary" onClick={() => this.save()}>Save</Button>
            </Form>            
         );
    }
}
 
export default Operator;