import * as React from "react";
import {Alert} from 'reactstrap'; 

const initialGlobalState = {
    error:  null,
    message: null
  };
  const GlobalStateContext = React.createContext(initialGlobalState);
  const DispatchStateContext = React.createContext(undefined);
  
  const GlobalStateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(
      (state, newValue) => ({ ...state, ...newValue }),
      initialGlobalState
    );
    return (
      <GlobalStateContext.Provider value={state}>
        <DispatchStateContext.Provider value={dispatch}>
          {children}
        </DispatchStateContext.Provider>
      </GlobalStateContext.Provider>
    );
  };
  
  const useGlobalState = () => [
    React.useContext(GlobalStateContext),
    React.useContext(DispatchStateContext)
  ];


function Errors(){
    const [state, dispatch] = useGlobalState();
    let  visible = false
    if(state.error) {
        visible = true
    }
    else{
        visible = false
    }
    function onDismiss(){
        dispatch({error: null})
    }
    return (
        <div>
          <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
            {state.error}
          </Alert>
          </div>
      );

}

function Alerts(){
    const [state, dispatch] = useGlobalState();
    let  visible = false
    if(state.message) {
        visible = true
    }
    else{
        visible = false
    }
    function onDismiss(){
        dispatch({message: null})
    }
    return (
        <div>
          <Alert color="primary" isOpen={visible} toggle={onDismiss} fade={false}>
            {state.message}
          </Alert>
          </div>
      );

}
export {Alerts, Errors, useGlobalState,GlobalStateProvider} 
export default GlobalStateProvider