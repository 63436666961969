import React, { Component, useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useHistory, useParams, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";

import { useGlobalState } from "./../global";
import { getData, postData, DynTable, localizeTZ } from "../Utils";

const decompSeconds = (sec) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  return `${hours}:${minutes}:${seconds}`;
};
export default function StatisticsV2() {
  const [state, dispatch] = useGlobalState();
  const [points, SetPoints] = useState([]);
  const [start, SetStart] = useState(new Date(new Date().getTime() - 24 * 3600 * 1000 * 7));
  const [end, SetEnd] = useState(new Date(new Date().getTime() + 24 * 3600 * 1000));
  const [filter, SetFilter] = useState("");
  const [sessions, SetSessions] = useState([]);
  const [displayRechargeAll, SetDisplayRechargeAll] = useState(false);
  useEffect(() => {
    getData("rentalpoints").then((points) => {
      SetPoints(points);
    });
  }, []);

  const recharge = (id) => {
    postData(`sessions/${id}/recharge`, {})
      .then((r) => {
        SetSessions(
          sessions.map((s) => {
            if (s.id == id) {
              s.payement_status = "Pending";
            }
            return s;
          }),
        );
      })
      .catch(console.log("error"));
  };

  const rechargeAll = () => {
    for (const session of sessions) {
      if (!["SUCCEEDED", "REFUNDED", "SOLVED", "CASH"].includes(session.payement_status)) {
        console.log(`Recharging ${session.id}`);
        recharge(session.id);
      }
    }
  };
  const search = (failedOnly = false) => {
    if (!start || !end) {
      dispatch({ error: "set start and end" });
      return false;
    }
    start.setHours(15);
    end.setHours(15);
    const startString = start.toISOString().slice(0, 10);
    const endString = end.toISOString().slice(0, 10);
    let url = `sessions/filter?start=${startString}&end=${endString}`;
    url = filter === "" ? url : `${url}&filter=${filter}`;
    if (failedOnly) {
      url = `${url}&failedOnly=True`;
      SetDisplayRechargeAll(true);
    } else SetDisplayRechargeAll(false);
    getData(url).then((sessions) => {
      SetSessions(
        sessions.map((s) => {
          const point_name = points.filter((p) => p.id == s.rental_point_id)[0].name;
          const point_code = points.filter((p) => p.id == s.rental_point_id)[0].code;
          s.price_total = s.price_total / 100;
          s.rental_point_id = point_name ? point_name : s.rental_point_id;
          s.rental_point_code = point_code;
          s.duration_hour = decompSeconds(s.duration_seconds);
          s.started_at = localizeTZ(s.started_at);
          s.ended_at = localizeTZ(s.ended_at);
          s.first_unlock_time = localizeTZ(s.first_unlock_time);
          s.payement_status = s.payement_status || "";
          s.payment_comment = s.refund_comment ? s.refund_comment : s.payment_solved_comment ? s.payment_solved_comment : "";
          return s;
        }),
      );
    });
  };
  return (
    <React.Fragment>
      <Form inline>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label className="mr-sm-2">Start</Label>
          <DatePicker dateFormat="yyyy-MM-dd" selected={start} onChange={(date) => SetStart(date)} />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label className="mr-sm-2">End</Label>
          <DatePicker dateFormat="yyyy-MM-dd" selected={end} onChange={(date) => SetEnd(date)} />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Label className="mr-sm-2">Filter</Label>
          <Input type="text" name="filter" value={filter || ""} onChange={(e) => SetFilter(e.target.value)} />
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Button color="primary" onClick={() => search()}>
            Search
          </Button>
        </FormGroup>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <Button color="primary" onClick={() => search(true)}>
            Failed only
          </Button>
        </FormGroup>
      </Form>
      <CSVLink data={sessions} filename={"rental_sessions.csv"}>
        {" "}
        CSV
      </CSVLink>
      <DrawDynamicStatisticsTable sessions={sessions} recharge={recharge} />
      {displayRechargeAll && (
        <Button color="primary" onClick={() => rechargeAll()}>
          Recharge all
        </Button>
      )}
    </React.Fragment>
  );
}

function DrawDynamicStatisticsTable(props) {
  const columns = [
    {
      Header: "Sessions",
      columns: [
        {
          Header: "session id",
          accessor: "link",
          sortType: "basic",
        },
        {
          Header: "Reg NR",
          accessor: "trailer_reg_number",
          sortType: "basic",
        },
        {
          Header: "Rentail point",
          accessor: "rental_point_id",
          sortType: "basic",
        },
        {
          Header: "Code",
          accessor: "rental_point_code",
          sortType: "basic",
        },
        {
          Header: "email",
          accessor: "email",
          sortType: "basic",
        },
        {
          Header: "Start",
          accessor: "started_at",
          sortType: "basic",
        },
        {
          Header: "End",
          accessor: "ended_at",
          sortType: "basic",
        },
        {
          Header: "Duration",
          accessor: "duration_hour",
          sortType: "basic",
        },
        {
          Header: "Payment status",
          accessor: "payement_status",
          sortType: "basic",
        },
        {
          Header: "Payment comment",
          accessor: "payment_comment",
          sortType: "basic",
        },
        {
          Header: "Price",
          accessor: "price_total",
          sortType: "basic",
        },
        {
          Header: "Recharge",
          accessor: "recharge",
          sortType: "basic",
        },
        {
          Header: "Invoice",
          accessor: "invoice",
          sortType: "basic",
        },
      ],
    },
  ];
  const data = props.sessions;
  const recharge = props.recharge;

  data.map((d) => {
    d.link = <a href={`/session/${d.id}`}>{d.id}</a>;
    d.recharge = !["SUCCEEDED", "REFUNDED", "SOLVED"].includes(d.payement_status) && d.price_total > 0 && !d.end_user_id.startsWith("manual_") ? <Button onClick={() => recharge(d.id)}>Recharge</Button> : "";
    d.invoice = d.end_user_id && !d.end_user_id.startsWith("man") ? <Link to={`/invoice/${d.id}`}>Invoice</Link> : "";
    return d;
  });
  console.log(data);
  return <DynTable columns={columns} data={data} />;
}
