import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
import { getData, postData } from "../Utils";
import { useGlobalState } from "../global";

export default function Settings() {
  const [state, dispatch] = useGlobalState();
  const [payment_key, setPayment_key] = useState("");
  const [booking_key, setBooking_key] = useState("");
  const [booking_amount, setBooking_amount] = useState("");
  useEffect(() => {
    getData("self").then((me) => {
      setPayment_key(me.payment_key);
      setBooking_amount(me.booking_amount);
      setBooking_key(me.booking_key);
    });
  }, []);
  function onChangeKey(e) {
    setPayment_key(e.target.value);
  }
  function save() {
    postData("self", { payment_key, booking_key, booking_amount })
      .then((r) => {
        dispatch({ message: "Save successful" });
      })
      .catch((error) => {
        dispatch({ error: `Error. not saved ${error}` });
      });
  }

  return (
    <Form>
      <FormGroup>
        <Label>Payment key</Label>
        <Input type="text" name="payment_key" value={payment_key} onChange={onChangeKey} />
      </FormGroup>
      <FormGroup>
        <Label>Booking key</Label>
        <Input type="text" name="payment_key" value={booking_key} onChange={(e) => setBooking_key(e.target.value)} />
      </FormGroup>
      <FormGroup>
        <Label>Booking amount</Label>
        <Input type="text" name="payment_key" value={booking_amount} onChange={(e) => setBooking_amount(e.target.value)} />
      </FormGroup>
      <Button color="primary" onClick={save}>
        Save
      </Button>
    </Form>
  );
}

class Settings_c extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onChangeValue = this.onChangeValue.bind(this);
  }
  onChangeValue(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  componentDidMount() {
    const payment_key = "123-AVD-KKK-nfdsafsd-333";
    this.setState({ payment_key });
  }
  render() {
    const payment_key = this.state.payment_key || "";
    return (
      <Form>
        <FormGroup>
          <Label>Payment key</Label>
          <Input type="text" name="payment_key" value={payment_key} onChange={this.onChangeValue} />
        </FormGroup>
        <Button color="primary">Save</Button>
      </Form>
    );
  }
}
